import React, { useState, useEffect } from "react";
import "./styles.css";

const cardIcons = [
  "fa-brands fa-facebook",
  "fa-brands fa-instagram",
  "fa-brands fa-twitter",
  "fa-brands fa-x-twitter",
  "fa-brands fa-twitch",
  "fa-brands fa-github",
  "fa-brands fa-gitlab",
  "fa-brands fa-whatsapp",
  "fa-brands fa-linkedin",
];

const generateCards = () => {
  const shuffledIcons = shuffle(cardIcons.concat(cardIcons));
  return shuffledIcons.map((icon, index) => ({
    icon,
    isFlipped: false,
    isMatched: false,
    id: index,
  }));
};

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function App() {
  const [cards, setCards] = useState(generateCards());
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(90);

  useEffect(() => {
    if (flippedCards.length === 2) {
      setTimeout(() => checkForMatch(), 1000);
    }
  }, [flippedCards]);

  useEffect(() => {
    if (timer === 0) {
      endGame();
    } else {
      const timerInterval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [timer]);

  const handleCardClick = (id) => {
    if (
      !flippedCards.includes(id) &&
      flippedCards.length < 2 &&
      !matchedCards.includes(id)
    ) {
      setFlippedCards([...flippedCards, id]);
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === id ? { ...card, isFlipped: true } : card
        )
      );
    }
  };

  const checkForMatch = () => {
    const [firstCard, secondCard] = flippedCards;
    if (cards[firstCard].icon === cards[secondCard].icon) {
      setMatchedCards([...matchedCards, firstCard, secondCard]);
      setScore(score + 1);
    } else {
      setCards((prevCards) =>
        prevCards.map((card) =>
          flippedCards.includes(card.id) ? { ...card, isFlipped: false } : card
        )
      );
    }
    setFlippedCards([]);
  };

  const resetGame = () => {
    setCards(generateCards());
    setFlippedCards([]);
    setMatchedCards([]);
    setScore(0);
    setTimer(90);
  };

  const endGame = () => {
    alert("Game Over! Your final score is: " + score);
    resetGame();
  };

  return (
    <div className="App">
      <h3>Memory Card Game</h3>
      <div className="details">
        <div className="score">Score: {score}</div>
        <div className="timer">Time Left: {timer} seconds</div>
      </div>
      <div className="board">
        {cards.map((card) => (
          <div
            key={card.id}
            className={`card ${
              card.isFlipped || matchedCards.includes(card.id) ? "flipped" : ""
            }`}
            onClick={() => handleCardClick(card.id)}
          >
            <div className="card-display">
              {!card.isFlipped ? (
                <div className="back">Flip Me</div>
              ) : (
                <div className="front">
                  <i className={card.icon}></i>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <button className="reset-btn" onClick={resetGame}>
        Reset Game
      </button>
    </div>
  );
}

export default App;
